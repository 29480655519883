import { startWatching } from "./dom-watcher";
import { CallTrackingResponse, Swap, SwapItem, SwapResponse } from "../types";
import { initAscClickToCall } from "./asc-click-to-call";
import { CachedCallTrackingResponse } from "./repositories/api-swap-repository";

export type CallTrackingRepository = {
    current: CachedCallTrackingResponse | null,
    pending: Promise<CallTrackingResponse>,
}

export type PageContext = {
    url: string
    referrer: string
}

export type PhoneNumberRenderer = (
    node: Node,
    swaps: SwapItem[],
) => void;


export type DniCache = {
    set<T>(key: string, data: T, ttl?: number): void;
    get<T>(key: string): T | null
}

export default class App {
    private static instance: App;
    private readonly callTracking: CallTrackingRepository;
    private readonly renderer: PhoneNumberRenderer;
    private readonly undoPhoneNumberRenderer: VoidFunction;

    private constructor(
        callTracking: CallTrackingRepository,
        phoneNumberRenderer: PhoneNumberRenderer,
        undoPhoneNumberRenderer: VoidFunction,
    ) {
        this.callTracking = callTracking;
        this.renderer = phoneNumberRenderer;
        this.undoPhoneNumberRenderer = undoPhoneNumberRenderer;
    }

    public static getInstance(
        callTracking: CallTrackingRepository,
        phoneNumberRenderer: PhoneNumberRenderer,
        undoPhoneNumberRenderer: VoidFunction,
    ): App {
        if (!App.instance) {
            App.instance = new App(callTracking, phoneNumberRenderer, undoPhoneNumberRenderer);
        }

        return App.instance;
    }

    public run(): App {
        const stop = startWatching(async (node) => {
            if (this.callTracking.current?.swaps) {
                this.renderer(node, this.callTracking.current.swaps);
            }
        });

        this.callTracking.pending.then((response) => {
            stop();

            this.undoPhoneNumberRenderer();

            if (response.isSessionTrackingEnabled) {
                initAscClickToCall();
            }

            startWatching(async (node) => {
                this.renderer(node, response.swaps);
            });
        });

        return this;
    }
}