import App, {
    PageContext,
    LocalStorage,
    initCallTracking,
    renderReplacement,
    error,
    info,
    isDebugging,
    PageViewEvent,
    debug,
    undoReplacements,
} from './lib';

import {initAscDataLayerMonitoring} from './lib/asc-data-layer';
import {initEventTracker} from './lib/event-tracker';
import {initGoogleAnalytics} from './lib/ga';
import {initHeartbeat} from './lib/heartbeat';

declare global {
    interface Window {
        _800js: {
            debug?: boolean
            ttl?: number
        };

        _800testing?: {
            url?: string,
            referrer?: string,
        }
    }
}

window._800js ||= {};
window._800testing ||= {};

const getPageContext = (): PageContext => {
    return {
        url: window._800testing?.url || window.location.href,
        referrer: window._800testing?.referrer || document.referrer,
    };
};

const script = document.currentScript;

const current = script
    ? new URL((script as HTMLScriptElement).src)
    : null;

const token = current?.searchParams.get('token') || String(process.env.PUBLIC_API_TOKEN || script?.getAttribute('data-token')) ;
const url =  current?.searchParams.get('backend') ||String(process.env.PUBLIC_API_ENDPOINT || script?.getAttribute('data-backend'))
    // remote trailing slash because we need to build different URL paths
    .replace(/\/$/, '');

if (!url) {
    error('missing url');
}

if (url) {
    const storage = new LocalStorage(window._800js.ttl || 3600);
    const pageContext = getPageContext();

    const callTracking = initCallTracking({
        storage,
        apiUrl: `${url}/calltrk/${token}`,
        pageContext: pageContext,
    });

    info('booting', window._800js);

    const app = App.getInstance(
        callTracking,
        renderReplacement,
        undoReplacements,
    )
        .run();

    debug('started app');

    if (isDebugging()) {
        Object.assign(window._800js, {app});
    }

    (async () => {
        const swapResponse = await callTracking.pending;

        if (!swapResponse?.sessionId || 
            !swapResponse?.isSessionTrackingEnabled) {
            return;
        }
        
        const sessionId = swapResponse?.sessionId;

        debug({sessionId})

        initEventTracker(url, token, sessionId);
        initHeartbeat(url, token, sessionId);
        initGoogleAnalytics();
        initAscDataLayerMonitoring();        

        window.dispatchEvent(
            new CustomEvent<PageViewEvent>('800dni.page_view', {
                detail: {
                    url: pageContext.url,
                    referrer: pageContext.referrer || null,
                },
            })
        )
    })();
}

