import {CallTrackingResponse, Swap} from '../../types';
import {DniCache, CallTrackingRepository, PageContext} from '../app';
import {error} from '../debugging';

const SESSION_KEY = '800cltrk.session';

type CallTrackingOptions = {
    storage: DniCache,
    apiUrl: string,
    pageContext: PageContext,
}

export type CachedCallTrackingResponse = {
    sessionId: string;
    swaps: Swap[]
}

export function initCallTracking(options: CallTrackingOptions): CallTrackingRepository {
    const init = async (sessionId?: string): Promise<CallTrackingResponse> => {
        const response : Response = await fetch(options.apiUrl, {
            method: 'POST',
            body: JSON.stringify({
                sessionId,
                // It's up to the BE to decide if the URL counts as a new 
                // session/touch or if it's a continuation of an existing one.
                landingPage: options.pageContext.url,
                referrer: options.pageContext.referrer,
                date: new Date().toISOString(),
            }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        });

        if (response.status !== 200) {
            error(await response.text());

            throw new Error('Received non-200 response while fetching swaps');
        }

        const payload = await response.json() as {data: CallTrackingResponse};

        return payload.data;
    };

    const existingSession = options.storage.get<CachedCallTrackingResponse>(SESSION_KEY);

    const results = init(existingSession?.sessionId);

    results.then(({ sessionId, swaps }) => {
        options.storage.set<CachedCallTrackingResponse>(SESSION_KEY, { sessionId, swaps }, (86400 * 365));
    });

    return {
        current: existingSession,
        pending: results,
    }
}
