export const initHeartbeat = function(url: string, token: string, sessionId: string) {
    const requestUrl = `${url}/dniHeartbeat`;
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({ token, sessionId }),
        headers: new Headers({
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        })
    };

    const beat = () => {
        fetch(requestUrl, requestOptions).catch(() => {
            // Do nothing
        });
    };

    setInterval(beat, 60000);
}    